.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 36.25%; 
  overflow: hidden;
  padding-top: 4%;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 0;
}