

input[type="radio"] {

  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  display: grid;
  place-content: center;
  background-color: #f6eadb;
}

input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid #EBEBEB;
  transform: scale(3);
  transition: 120ms transform ease-in-out;
  margin-right: 5px;
 
}

input[type="radio"]:checked::before {
  transform: scale(3);
  background-color: #D6D6D6;
}
