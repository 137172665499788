@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: antialiased;
}

html, body{
  background-color: #f6eadb;
}
@font-face {
  font-family: "NOIRetBLANC";
  src: local("NOIRetBLANC"),
    url("./assets/Font/NOIRetBLANC_medium_bold.otf") format("truetype");
  font-weight: 400;
}

.form-control {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}
.StripeElement {
  height: 70px;
  padding: 20px 0 0 12px;
  width: 100%;
  background-color: #ebebeb !important;
  color:black !important;
  margin-bottom: 16px;
}

.InputContainer {
  margin-top: 10px;
  height: 100%;
}

.ElementsApp.is-autofilled .InputElement:not(.is-empty), .ElementsApp.is-autofilled .InputElement:-webkit-autofill {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
}